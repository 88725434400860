
.industryDetail {
  // padding-bottom: 40px;
  width: 1100px;
}
.topBox {
  width: 100%;
  // padding-top: 20px;
  // padding-left: 30px;
  //   height: 551px;
  .sectionLeft {
    width: 756px;
    box-sizing: border-box;
    flex: 1;
    padding-right: 20px;
    // height: 551px;
    .info {
      width: 756px;
      box-sizing: border-box;
      padding: 24px 30px 24px 0px;
      .info-title {
        margin-bottom: 12px;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: Medium;
          color: #101010;
          line-height: 24px;
        }
      }
      .info-item {
        display: inline-block;
        border-radius: 34.5px;
        padding: 10px 16px;
        border: 1px solid #f1f1f1;
        color: #101010;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        background-color: #fafafa;
        font-family: Regular;
        margin-right: 12px;
      }
      .info-value {
        font-weight: 500;
        font-size: 16px;
        line-height: 36px;
        color: #404040;
        font-family: Regular;
        text-align: justify;
      }

      .info-img {
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 68px;
          height: 72px;
        }
        .empty-text {
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #8896A7;
        }
      }

      .info-consulting {
        .tit {
          background-color: #2885ff;
          padding: 6px;
          box-sizing: border-box;
          display: inline-block;
          border-radius: 2px 8px 8px 8px;
          color: #ffffff;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 20px;
        }
        .consulting-item {
          margin-bottom: 20px;
          .label {
            font-size: 12px;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }

          .value {
            font-size: 12px;
            line-height: 18px;
            color: #404040;
          }
        }
      }
    }
    .titleInfo {
      width: 756px;
      // height: 158px;
      padding: 24px 30px 24px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      .groupLeft {
        margin-right: 23px;
        z-index: 126;
        // width: 110px;
        // height: 110px;
        .img1 {
          z-index: 127;
          width: 110px;
          height: 110px;
          border-radius: 6px;
        }
      }
      .groupRight {
        margin-top: -2px;
        .title1 {
          width: 112px;
          height: 28px;
          color: rgba(16, 16, 16, 1);
          font-size: 24px;
          font-family: Bold;
          white-space: nowrap;
          line-height: 36px;
          text-align: left;
          font-weight: 600;
          // margin-left: 3px;
          // margin-bottom: 6px;  
        }

        .title2 {
          // background: red;
          // margin-top: -20px;
          @mixin title2Font {
            font-size: 12px;
            font-family: Regular;
            line-height: 18px;
            color: #8896a7;
          }
          .top {
            display: flex;
            justify-content: space-between;
            margin-top: 4px;
       
            .top-word:nth-child(1) {
              .scale {
                margin-bottom: 8px;
              }
            }
            .top-word:nth-child(2) {
              margin-left: 80px;
              .alliance {
                margin-bottom: 8px
              }
              }
          
            .top-word {
              .label {
                @include title2Font;
                margin-right: 12px;
                
              }
              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }
          }
          .middle{
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            margin-top: 12px;
            .word {
              .label {
                @include title2Font;
                margin-right: 12px;
              }
              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }

          }
          .bottom {
            .wordBottom {
              .label {
                @include title2Font;
                margin-right: 12px;
              }

              .value {
                @include title2Font;
                color: #404040;
                font-weight: 500;
              }
            }
          }
        }
        .word {
          //   width: 178px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          font-family: Regular;
          white-space: nowrap;
          line-height: 16px;
          text-align: left;
          // margin-top: 16px;
          .word-box{
            margin-left: 135px;

          }
        }
        .wordBottom {
          //   width: 338px;
          height: 16px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          font-family: Regular;
          white-space: nowrap;
          line-height: 16px;
          text-align: left;
          margin-top: 10px;
        }
      }
    }
    .inline {
      //   width: 100%;
      height: 1px;
      width: 756px;
      background-color: rgba(216, 216, 216, 1);
      // margin-top: 24px;
    }
    .title {
      height: 28px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      font-family: Regular;
      white-space: nowrap;
      line-height: 28px;
      text-align: left;
      margin-top: 24px;
    }
    // .artContainer {
    //   width: 100%;
    //   //   height: 150px;
    //   margin-top: 24px;
    //   display: block;
    //   overflow-wrap: break-word;
    //   color: rgba(16, 16, 16, 1);
    //   font-size: 16px;
    //   font-family: Regular;
    //   line-height: 30px;
    //   text-align: left;
    //   margin-top: 20px;
    //   //   padding: 20px;
    // }
    .contactTitle {
      height: 28px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      font-family: Regular;
      white-space: nowrap;
      line-height: 28px;
      text-align: left;
      margin-top: 42px;
    }
    .contactName {
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: Bold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 20px;
      font-weight: 500;
    }
  }
  .sectionRight {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 622px;
    // border: 1px solid rgba(234, 234, 234, 1);
    width: 300px;
    box-sizing: border-box;
    padding-top: 24px;
    .titleLine {
      width: 100%;
      height: 4px;
      // background-color: rgba(20, 86, 159, 1);
    }
    .title {
      width: 300px;
      height: 38px;
      box-sizing: border-box;
      padding: 11px 30px 11px 0px;
      img {
        width: 8px;
        height: 10px;
        margin-right: 12px;
      }

      span {
        font-size: 16px;
        color: #101010;
        line-height: 24px;
        font-family: Medium;
      }
    }
    .item {
      box-sizing: border-box;
      padding: 20px 0px;
      width: 300px;
      height: 106px;
      // margin: 26px 0 0 20px;
      cursor: pointer;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      flex-direction: row;
      .left {
        .imgThem {
          z-index: 58;
          height: 66px;
          width: 66px;
          border-radius: 6px;
        }
      }
      .left-no {
        width: 66px;
        height: 66px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 66px;
        .imgThem {
          z-index: 58;
          height: 16px;
          width: 16px;
        }
      }
      .right {
        box-sizing: border-box;
        padding-left: 12px;
        padding-top: 4px;
        @mixin textFont {
          font-size: 12px;
          font-family: Regular;
          // font-weight: 600;
          line-height: 18px;
          color: #8896a7;
        }
        .text1 {
          font-size: 14px;
          font-family: Regular;
          font-weight: 600;
          color: #101010;
          margin-bottom: 10px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 162px;
        }
        .text2 {
          .label {
            @include textFont;
            margin-right: 12px;
          }
          .value {
            @include textFont;
            color: #404040;
            font-weight: 400
          }
        }
        .text3 {
          .label {
            @include textFont;
            margin-right: 18px;
          }
          .value {
            @include textFont;
            color: #101010;
          }
        }
      }
      .groupLeft {
        z-index: 57;
        position: relative;
        width: 120px;
        height: 90px;
        overflow: hidden;
      }
    }
    // .groupRight {
    //   width: 206px;
    //   height: 90px;
    //   .text1 {
    //     // width: 80px;
    //     height: 16px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(16, 16, 16, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 16px;
    //     text-align: left;
    //   }
    //   .text2 {
    //     // width: 205px;
    //     height: 24px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(157, 158, 157, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 24px;
    //     text-align: left;
    //     margin: 14px 0 0 1px;
    //   }
    //   .text3 {
    //     // width: 205px;
    //     height: 24px;
    //     display: block;
    //     overflow-wrap: break-word;
    //     color: rgba(157, 158, 157, 1);
    //     font-size: 16px;
    //     font-family: Regular;
    //     white-space: nowrap;
    //     line-height: 24px;
    //     text-align: left;
    //     margin: 12px 0 0 1px;
    //   }
    // }
  }
}
.infoAddress {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
.infoTel {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
.infoEmail {
  height: 16px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  white-space: nowrap;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
